exports.components = {
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-books-index-tsx": () => import("./../../../src/pages/books/index.tsx" /* webpackChunkName: "component---src-pages-books-index-tsx" */),
  "component---src-pages-budgetmate-tsx": () => import("./../../../src/pages/budgetmate.tsx" /* webpackChunkName: "component---src-pages-budgetmate-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-videos-tsx": () => import("./../../../src/pages/videos.tsx" /* webpackChunkName: "component---src-pages-videos-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-book-tsx": () => import("./../../../src/templates/book.tsx" /* webpackChunkName: "component---src-templates-book-tsx" */)
}

