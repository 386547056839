/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require('prismjs/themes/prism-solarizedlight.css');

// track pageviews with plausible
// exports.onRouteUpdate = function ({ location }) {
//     if (
//       process.env.NODE_ENV === `production` &&
//       typeof window.plausible === `object`
//     ) {
//       window.plausible('pageview');
//     }
// };
